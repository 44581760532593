import { Link } from "@StarberryUtils";
import React,{useEffect, useState} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import  "./FixedButtons.scss";

  const FixedButtons=(props)=>{
    
  return(
  <section className="fixed-btns d-flex d-xl-none d-lg-flex">
    <Link to="#" className="btn book-btn">book a viewing</Link>
    <Link to="/market-your-property/book-a-valuation/" className="btn ask-btn btn-success d-md-block">value my home</Link>
    <Link to="#" className="btn book-btn d-none d-md-block">ask a question</Link>
  </section>
)
  }

export default FixedButtons
